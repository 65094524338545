import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import Theme from '../../Theme';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

function MemberPendingRegistration(props) {
  const {id} = qs.parse(props.location.search);
  const [record, setRecord] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setRecord(
          await JStorage.fetchOneDocument('pending_registration', {id}, null, {
            token: null,
          }),
        );
      } catch (ex) {
        console.warn('REV_DBG', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [id]);

  if (!record) {
    return null;
  }

  return (
    <Wrapper>
      <h1>TWPAA註冊申請</h1>
      <h3 style={{textAlign: 'center'}}>核准狀態: {record.status || '---'}</h3>

      <h2>基本資料</h2>
      <section>
        <div className="field file">
          <label style={{alignSelf: 'flex-start'}}>照片</label>
          <div className="image-picker">
            <label>
              <img
                src={record.photo}
                alt="selected file"
                style={{width: '100%', height: '100%', objectFit: 'contain'}}
              />
            </label>
          </div>
        </div>
        <div className="field">
          <label className="required">中文姓名</label>
          <div>{record.name}</div>
        </div>
        <div className="field">
          <label className="required">英文姓名(同護照姓名)</label>
        </div>
        <div className="field">
          <label className="required">性別</label>
          <Ant.Radio.Group value={record.gender}>
            <Ant.Radio value={'male'}>男</Ant.Radio>
            <Ant.Radio value={'female'}>女</Ant.Radio>
          </Ant.Radio.Group>
        </div>

        <div className="field">
          <label className="required">身分證字號</label>
        </div>
        <div className="field">
          <label className="required">出生年月日</label>
        </div>
        <div className="field">
          <label className="required">手機</label>
        </div>
        <div className="field">
          <label className="required">EMAIL(1)</label>
          <div>{record.email}</div>
        </div>
        <div className="field">
          <label>EMAIL(2)</label>
        </div>
        <div className="field">
          <label className="required">聯絡地址</label>
        </div>

        <div className="field">
          <label className="required">專利師證書字號</label>
        </div>

        <div className="field">
          <label className="required">國考年度</label>
        </div>

        <div className="field">
          <label style={{alignSelf: 'flex-start'}} className="required">
            專利師證書
          </label>
        </div>
      </section>

      <h2>執行業務處所</h2>
      <section>
        <div className="field">
          <label>中文名稱</label>
        </div>
        <div className="field">
          <label>英文名稱</label>
        </div>
        <div className="field">
          <label>英文地址</label>
        </div>
        <div className="field">
          <label>聯絡電話</label>
        </div>
        <div className="field">
          <label>傳真</label>
        </div>
        <div className="field">
          <label>統一編號</label>
        </div>
      </section>

      <h2>訴代專區</h2>
      <section>
        <div className="field">
          <label>年度</label>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 40px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 4px solid ${Theme.colors.main};
  }

  & > section {
    padding-left: 20px;
  }

  .field {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;

    & > label {
      width: 160px;
      &.required {
        &::after {
          content: '*';
          color: red;
          margin-top: -8px;
          margin-left: 2px;
        }
      }
    }

    input {
      width: 120px;

      &.long {
        width: 250px;
      }
    }

    & > .image-picker {
      label {
        width: 150px;
        height: 150px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input[type='file'] {
        display: none;
      }
    }

    & > .file-picker {
      label {
        padding: 8px 16px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input[type='file'] {
        display: none;
      }
    }
  }
`;

export default withPageEntry(MemberPendingRegistration);
